import apiFactory from '~/api/factory'

export default (ctx, inject) => {
  // inject the repository in the context (ctx.app.$api)
  // And in the Vue instances (this.$api in your components)
  // ctx.$axios.setHeader('Content-Type', 'application/vnd.api+json', ['get'])
  const apiWithAxios = apiFactory(ctx)
  const api = {
    shopping: apiWithAxios('shopping'),
    addresses: apiWithAxios('addresses'),
    customerComments: apiWithAxios('customerComments'),
    cart: apiWithAxios('cart'),
    comments: apiWithAxios('comments'),
    covers: apiWithAxios('covers'),
    customer_comments: apiWithAxios('customer_comments'),
    galleries: apiWithAxios('galleries'),
    initData: apiWithAxios('initData'),
    orders: apiWithAxios('orders'),
    order_paid: apiWithAxios('order_paid'),
    post_comment: apiWithAxios('post_comment'),
    post: apiWithAxios('post'),
    posts: apiWithAxios('posts'),
    products: apiWithAxios('products'),
    sectionalProducts: apiWithAxios('sectional_products'),
    singleProduct: apiWithAxios('single_product'),
    tax: apiWithAxios('taxes'),
    thankYou: apiWithAxios('thankYou'),
    orderLookup: apiWithAxios('orderLookup'),
    user: apiWithAxios('user'),
    addressValidation: apiWithAxios('addressValidation'),
    signIn: apiWithAxios('sign_in'),
  }
  if (inject !== undefined) {
    return inject('api', api)
  }
    return api
}
