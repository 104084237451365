import _ from 'lodash'

export const state = () => ({
  showHorizontalFlyout: false,
  showFlyout: false,
  currentFabricCategoryName: 'All',
  coversCategory: 'Chair',
  sectionalCategory: '',
  coversStyle: 'Tux',
  enrolledInRiskFree: false,
  furniture: 'none',
  defaultFabric: {
    description: '100% polyester. The Oscar line is smooth to the touch with a multi-tonal texture to the eye. Sterling is a beautiful and versatile light medium gray, it\'s one of our most popular fabrics because it looks good in every style home!',
    outOfStock: false,
    feelTexture: 'Multi-tonal gray with light, medium, and dark colors.',
    familyPetFriendliness: 'Machine washable, spot cleans easily, anti-wrinkle. The tight woven pattern provides medium to high resistance to pet nails. Lint and pet hair wipes off easily.',
    materialCare: '100% Polyester. Spot-clean and machine wash: cold/gentle cycle, spin dry, and put the fabric back on while still damp.',
    detailDescription: 'Smooth woven texture. No visible nap.',
    fabricLevelId: 1,
    fabricLevelName: 'Essential Collection',
    name: 'Oscar Sterling',
  },
  selectedFabric: {
    description: '100% polyester. The Oscar line is smooth to the touch with a multi-tonal texture to the eye. Sterling is a beautiful and versatile light medium gray, it\'s one of our most popular fabrics because it looks good in every style home!',
    outOfStock: false,
    feelTexture: 'Multi-tonal gray with light, medium, and dark colors.',
    familyPetFriendliness: 'Machine washable, spot cleans easily, anti-wrinkle. The tight woven pattern provides medium to high resistance to pet nails. Lint and pet hair wipes off easily.',
    materialCare: '100% Polyester. Spot-clean and machine wash: cold/gentle cycle, spin dry, and put the fabric back on while still damp.',
    detailDescription: 'Smooth woven texture. No visible nap.',
    fabricLevelId: 1,
    fabricLevelName: 'Essential Collection',
    name: 'Oscar Sterling',
  },
  fabricLevel: 1,
  shoppingTotal: 0.0,
  // product is the whole object, quantity is how many there are, name is display name
  // fabric is the chosen fabric for the fabric,
  chosenComponents: [],
  fabricCostModifier: 1,
  fabricFlyout: 'Oscar Sterling',
  furnitureDisplayImage: '',
  totalWithFabric: 0,
  slideout: '', // 0 is for components and 1 is for fabrics (only used for sectionals page)
  shoppingSelection: 'Tux',
  singleProduct: {
    /* product */
  },
  viewedRiskFreeFabrics: false,
})

export const getters = {
  showHorizontalFlyout: state => state.showHorizontalFlyout,
  showFlyout: state => state.showFlyout,
  currentFabricCategoryName: state => state.currentFabricCategoryName,
  // when a customer increments a component by 1 for sectionals and covers
  chosenComponents: state => state.chosenComponents,
  coversCategory: state => state.coversCategory,
  sectionalCategory: state => state.sectionalCategory,
  coversStyle: state => state.coversStyle,
  defaultFabric: state => state.defaultFabric,
  fabricLevel: state => state.fabricLevel,
  furnitureDisplayImage: state => state.furnitureDisplayImage,
  shoppingSelection: state => state.shoppingSelection,
  slideout: state => state.slideout,
  enrolledInRiskFree: state => state.enrolledInRiskFree,
  fabricCostModifier: state => state.fabricCostModifier,
  fabricFlyout: state => state.fabricFlyout,
  selectedFurniture: state => state.furniture,
  selectedFabric: state => state.selectedFabric,
  singleProduct: state => state.singleProduct,
  totalWithFabric: state => state.totalWithFabric,
  shoppingTotal: state => state.shoppingTotal,
  // once they are enrolled in risk free and view fabrics, we show that "step" as a check mark
  // that's all this boolean is used for
  viewedRiskFreeFabrics: state => state.viewedRiskFreeFabrics,
}

export const mutations = {
  // (state, getters, rootState, rootGetters)
  unenrollInRiskFree (state) {
    if (state.enrolledInRiskFree) {
      state.chosenComponents = []
      state.totalWithFabric = 0
      state.viewedRiskFreeFabrics = false
      state.enrolledInRiskFree = false
    }
  },
  enrollInRiskFree (state) {
    if (!state.enrollInRiskFree) {
      state.enrolledInRiskFree = true
      state.chosenComponents = []
      state.totalWithFabric = 0
    }
  },
  // sets the classification of items to display: brook, laney
  setFurnitureDisplayImage (state, payload) {
    state.furnitureDisplayImage = payload
  },
  // sets the classification of items to display: brook, laney
  setShoppingSelection (state, payload) {
    state.shoppingSelection = payload
  },
  setShowHorizontalFlyout (state, payload) {
    state.showHorizontalFlyout = payload
  },
  setShowFlyout (state, payload) {
    state.showFlyout = payload
  },
  setCurrentFabricCategoryName (state, payload) {
    state.currentFabricCategoryName = payload
  },
  setSectionalCategory (state, payload) {
    state.sectionalCategory = payload
  },
  setCoversCategory (state, payload) {
    state.coversCategory = payload
  },
  setCoversStyle (state, payload) {
    state.coversStyle = payload
  },
  setFurniture (state, payload) {
    state.furniture = payload
  },
  setSelectedFabricDetail (state, fabricName) {
    // this probably isn't the right way to do this #codesmell?
    state.selectedFabric = this.getters['initData/fabricDetails'][fabricName].data.attributes
  },
  setFabricFlyout (state, payload) {
    state.fabricFlyout = payload
  },
  setFabricLevel (state, level) {
    state.fabricLevel = level
  },
  setTotalWithFabric (state) {
    const priceFunction = `tier${state.selectedFabric.fabricLevelId}Price`
    if (state.singleProduct.hasOwnProperty(priceFunction)) {
      state.totalWithFabric = parseFloat(state.singleProduct[priceFunction])
    } else if (state.chosenComponents.length > 0) {
      state.totalWithFabric = _.sumBy(state.chosenComponents, component => parseFloat(component.product[priceFunction]) * component.quantity)
    } else {
      state.totalWithFabric = 0
    }
  },
  // this determines which panel will open when changing page
  setSlideout (state, name) {
    state.slideout = name
  },
  clearSlideout (state) {
    state.slideout = null
  },
  setShoppingTotal (state, number) {
    state.shoppingTotal = number
  },
  setSingleProduct (state, product) {
    state.singleProduct = product
  },
  setViewedRiskFreeFabrics (state, payload) {
    state.viewedRiskFreeFabrics = payload
  },
  // for sectional components only
  createComponent (state, product) {
    if (product.quantity === undefined) {
      product.quantity = 1
    }
    state.chosenComponents.push({ product: product.item, quantity: product.quantity })
  },
  modifyComponentNumber (state, payload) {
    const components = _.find(
      state.chosenComponents,
      componentDetails => componentDetails.product.displayName === payload.name,
    )
    components.quantity += payload.number
  },
  destroyComponent (state, name) {
    state.chosenComponents = _.reject(
      state.chosenComponents,
      item => item.product.displayName === name,
    )
  },
  // When switching categories they want to persist the chosen components
  // There should only be sectional component/ottomans in the cart at this point
  // pass in all the new category products as the param
  switchComponent (state, products) {
    // find product by the chosenComponents display name and
    // replace the chosenComponet.product with the product you find
    _.each(state.chosenComponents, component => {
      const newProduct = _.find(
        products,
        product => product.attributes.displayName === component.product.displayName,
      )
      component.product = newProduct.attributes
    })
  },
  resetComponents (state) {
    // I persist the fabrics across classifications changes, so can't reset the fabric Cost Modifier
    // state.fabricCostModifier = 1
    // don't need to reset base total because that's different everytime
    state.chosenComponents = []
    state.totalWithFabric = 0
  },
}
