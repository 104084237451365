<template>
  <v-container fluid>
    <v-row class="top-navbar">
      <v-col
        cols="12"
        class="white--text"
        :class="mobile() ? 'text-center' : 'text-right'"
      >
        <n-link v-if="loggedIn" class="mb-0 beige-color--text" to="/employees/sign_in">
          {{ email }}<span class="ml-2">|</span>
        </n-link>
        <div>
          <div class="d-flex justify-center">
            {{ staticData.bannerText }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script scoped>
import { mapGetters } from 'vuex'

export default {
  name: 'TheTopNavBar',
  props: {
    loaded: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      email: 'employees/email',
      loggedIn: 'employees/loggedIn',
      staticData: 'initData/staticData',
    }),
  },
}
</script>

<style lang="scss" scoped>
  .top-navbar {
    background-color: $success-color;
    a {
      display: inline-block;
      &:hover {
        color: #f4f4f4 !important;
      }
    }
  }
</style>
