import _ from 'lodash'

export default {
  modifyGalleriesForVueLightGallery (gallery) {
    let tempPhoto = {}
    const imagesHolder = []
    _.each(gallery, (photo) => {
      tempPhoto = {
        title: 'loading...',
        tooltip: photo.attributes.title,
        url: photo.attributes.url,
        description: '<div>\n'
          + `<h5 class="mb-0 white--text uppercase mb-4">${photo.attributes.title}</h5>\n`
          + `<p class="mb-0 medium-gray--text text--small">${photo.attributes.description.replace(/[•\t.+]/g, '')}</p>`,
      }
      imagesHolder.push(tempPhoto)
    })
    return imagesHolder
  },
}
