export const state = () => ({
  allComments: [],
  comments: [],
  numberOfComments: 0,
})

export const getters = {
  allComments: (state) => {
    return state.allComments
  },
  comments: (state) => {
    return state.comments
  },
  numberOfComments: (state) => {
    return state.numberOfComments
  },
}

export const mutations = {
  setAllComments (state, payload) {
    state.allComments = payload
    state.numberOfComments = payload.length
    // for first hits you need to set the comments to the first 10
    state.comments = state.allComments.slice(0, 10)
  },
  setComments (state, pageNumber) {
    pageNumber -= 1 // offset for zero based index (see #setAllComments)
    const commentCount = pageNumber * 10
    state.comments = state.allComments.slice(commentCount, commentCount + 10)
  },
}
