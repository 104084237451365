// import axios from 'axios';

let alreadyDone = false

export default async function clientInit (context) {
  if (alreadyDone) { return null }
  console.log('nuxt client init')
  try {
    // const initData = await context.app.$api.initData.get('nuxt_server/index')
    // console.log(initData)
    const axiosCalls = [
      context.app.$api.initData.get('nuxt_server/index', 'v2').catch((e) => {
        console.error(`${e.message} for ${e.config.url}`)
      }),
      await context.app.$api.galleries.get('galleries').catch((e) => {
        console.error(`${e.message} for ${e.config.url}`)
      }),
      // await context.app.$api.posts.get('posts').catch((e) => {
      //   console.error(`${e.message} for ${e.config.url}`)
      // }),
    ]
    if (context.store.getters['cart/lineItems'].length > 0) {
      context.store.getters['cart/lineItems'].forEach(async (item) => {
        if (item.styleProduct) {
          const product = await context.app.$api.shopping.get(`products/${item.styleProduct.id}`)
          await context.store.commit('cart/updateProduct', { item, styleProduct: product.data.attributes })
        }
      })
    }
    const [initData, galleries] = await Promise.all(axiosCalls)
    alreadyDone = true
    await context.store.commit('galleries/setAllGalleries', galleries)
    // await context.store.commit('blog/setAllPosts', blogPosts)
    return await context.store.commit('initData/setInitData', initData)
  } catch (e) {
    console.error(e)
    return e
  }
}
