// import _ from 'lodash'
import jwt from 'jwt-simple'
import mockApi from './mock/index'

export default ctx => resource => ({
  create (payload, route) {
    if (route === undefined) {
      route = resource
    }
    this.setAxiosHeaders()
    console.debug(`CREATE request to ${process.env.version}/${resource}`)
    if (process.env.useBackEnd !== 'true' && process.env.apiClient === 'mock') {
      return mockApi.orders()
    }
    try {
      return ctx.$axios.$post(`${process.env.version}/${route}`, payload)
    } catch (e) {
      return this.axiosRequestErrorHandler(e)
    }
  },
  delete (route) {
    this.setAxiosHeaders()
    console.debug(`DELETE request to ${route}`)
    try {
      return ctx.$axios.$delete(`${process.env.version}/${route}`)
    } catch (e) {
      return this.axiosRequestErrorHandler(e)
    }
  },
  get (route, version) {
    if (process.env.useBackEnd !== 'true' && process.env.apiClient === 'mock') {
      let f = 'fetchSingleProduct'
      if (resource === 'shopping') {
        if (route.includes('sectionals')) { f = 'fetchSectionalProducts' }
        if (route.includes('covers')) { f = 'fetchCovers' }
      } else {
        f = `fetch${resource[0].toUpperCase()}${resource.slice(1)}`
      }
      return mockApi[f]()
    }
      this.setAxiosHeaders()
      try {
        return ctx.$axios.$get(`${version || process.env.version}/${route}`)
      } catch (e) {
        return this.axiosRequestErrorHandler(e)
      }
  },
  // setCsrf (response) {
  //   const cookies = _.head(response.headers['set-cookie']).split(',')
  //   const sessionCookie = _.find(cookies, function (cookie) {
  //     return _.startsWith(cookie, 'CSRF_TOKEN=')
  //   })
  //   const csrfToken = _.replace(sessionCookie, 'CSRF_TOKEN=', '')
  //   // console.debug(`new user ${csrfToken.slice(0, 7)}...`)
  //   ctx.app.$auth.$storage.setUniversal('csrf_token', csrfToken, false)
  //   ctx.$axios.setHeader('X-CSRF-TOKEN', `${csrfToken}`)
  // },
  // can't set headers in nuxt config because of bug, so I set them here
  axiosRequestErrorHandler (e) {
    console.error(e)
    if ( e.response.status === 500) {
      throw e
    }
  },
  setAxiosHeaders () {
    const encodedToken = jwt.encode(process.env.RAILS_PASSPHRASE, process.env.RAILS_SECRET, process.env.RAILS_ALGORITHM)
    ctx.$axios.setHeader('Authorization', `Bearer ${encodedToken}`)
    ctx.$axios.setHeader('withCredentials', true)
    ctx.$axios.setHeader('Content-Type', 'application/json;charset=UTF-8')
    ctx.$axios.setHeader('Access-Control-Allow-Credentials', true)
    ctx.$axios.setHeader(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept',
    )
  },
  update (payload, route) {
    if (process.env.useBackEnd !== 'true' && process.env.apiClient === 'mock') {
      const f = `fetch${resource[0].toUpperCase()}${resource.slice(1)}`
      return mockApi[f]()
    }
    this.setAxiosHeaders()
    console.debug(`PUT request to ${route}`)
    try {
      return ctx.$axios.$put(`${process.env.version}/${route}`, payload)
    } catch (e) {
      return this.axiosRequestErrorHandler(e)
    }
  },
  validate (payload) {
    if (process.env.apiClient === 'mock' && process.env.useBackEnd !== 'true') {
      console.debug(`mock call to ${process.env.version}/${resource}/validate`)
      return mockApi.addressValidation()
    }
      this.setAxiosHeaders()
      console.debug(`VALIDATION call to ${process.env.version}/${resource}`)
      try {
        return ctx.$axios.$post(`${process.env.version}/${resource}/validate`, payload)
      } catch (e) {
        return this.axiosRequestErrorHandler(e)
      }
  },
})
