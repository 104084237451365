export const state = () => ({
  category: {},
  routeParamsMapper: {
    sofas: 'Sofa',
    couches: 'Couch',
    loveseats: 'Loveseat',
    chairs: 'Chair',
    'chair-half': 'Chair & Half',
    ottomans: 'Ottoman',
    ottoman: 'Ottoman',
    pillows: 'Pillow',
    sectionals: 'Sectional',
    all: 'Swatch',
    swatches: 'Swatch',
    laney: 'Laney',
    ava: 'Ava',
    millie: 'Millie',
    brynn: 'Brynn',
    ezra: 'Ezra',
    ray: 'Ray',
    jovie: 'Jovie',
    griffin: 'Griffin',
    tux: 'Tux',
    haven: 'Haven',
    bella: 'Bella',
    ali: 'Ali',
    brook: 'Brook',
    'armless tb': 'Armless TB',
    'armless lb': 'Armless LB',
    bay: 'Bay',
    cozy: 'Cozy',
    standard: 'Standard',
    square: 'Square',
    lounger: 'Lounger',
    modular: 'Modular',
    corner: 'Corner',
    rectangle: 'Rectangle',
    box: 'Box',
    oversize: 'Oversize',
    'new-covers': 'Covers',
    parts: 'Replacements',
    lounge: 'Lounge',
    'deep-seat-sectionals': 'Deep Seat Sectional',
    'standard-seat-sectionals': 'Standard Seat Sectional',
  },
})

export const getters = {
  category: (state) => {
    return state.category
  },
  categoryName: (state) => {
    if (state.category.name === undefined) { return '' }
    return state.category.name
  },
  routeParamsMapper: (state) => {
    return state.routeParamsMapper
  },
}

export const mutations = {
  setCategory (state, category) {
    state.category = category
  },
}
