<template>
  <v-footer
    dark
    class="footer section"
  >
    <p id="developer-info" class="d-none">
      {{ latestCommit }}
    </p>
    <v-container>
      <v-row>
        <v-col
          v-show="!mobile()"
          cols="3"
        >
          <v-list>
            <h4 class="font-weight-bold qwincey">
              Shop
            </h4>
            <v-list-item
              v-for="(menu, index) in menuItems"
              :key="index"
              router
              :to="menuLink(menu.slug)"
              :ripple="false"
            >
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          v-show="!mobile()"
          cols="3"
        >
          <v-list>
            <h4 class="font-weight-bold qwincey">
              Features
            </h4>
            <v-list-item
              v-for="link in links"
              :key="link.name"
              router
              :to="link.route"
              :ripple="false"
            >
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          v-show="!mobile()"
          cols="3"
        >
          <v-list class="pb-2">
            <h4 class="font-weight-bold qwincey">
              Reviews
            </h4>
            <v-list-item
              router
              to="/customer_comments"
              :ripple="false"
            >
              <v-list-item-title>Customer Reviews</v-list-item-title>
            </v-list-item>
            <v-list-item
              :ripple="false"
              href="https://customerreviews.google.com/v/merchant?q=homereserve.com&c=US&v=19&hl=en_US"
              target="_blank"
            >
              <v-list-item-title>Google Reviews</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list class="pb-2">
            <h4 class="font-weight-bold qwincey">
              Inspiration
            </h4>
            <v-list-item
              router
              to="/gallery"
              :ripple="false"
            >
              <v-list-item-title>Image Gallery</v-list-item-title>
            </v-list-item>
            <v-list-item
              :ripple="false"
              href="https://www.homereserveservices.com/"
              target="_blank"
            >
              <v-list-item-title>Free Design Services</v-list-item-title>
            </v-list-item>
            <v-list-item
              router
              to="/family-friendly"
              :ripple="false"
            >
              <v-list-item-title>Family-Friendly</v-list-item-title>
            </v-list-item>
            <v-list-item
              router
              to="/favorite-layouts"
              :ripple="false"
            >
              <v-list-item-title>Our Favorite Layouts</v-list-item-title>
            </v-list-item>
            <v-list-item
              router
              to="/blog"
              :ripple="false"
            >
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
              router
              to="/customer-layouts"
              :ripple="false"
            >
              <v-list-item-title>Customer Layouts</v-list-item-title>
            </v-list-item> -->
            <!-- HRES-321 Blog removed for now. May come back in the future. -->
            <!-- <v-list-item
              router
              to="/blog"
              :ripple="false"
            >
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item> -->
          </v-list>
          <v-list class="pb-2">
            <h4 class="font-weight-bold qwincey">
              Partnerships
            </h4>
            <v-list-item
              :ripple="false"
              href="https://www.homereservepartners.com/"
              target="_blank"
            >
              <v-list-item-title>Apply Here</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          :cols="mobile() ? '12' : '3'"
          :class="mobile() ? 'd-flex flex-column align-center' : ''"
        >
          <h4 class="font-weight-bold qwincey pt-2">
            {{ mobile() ? 'CONTACT US' : 'Support' }}
          </h4>
          <n-link to="/support">
            Get Support
          </n-link><br>
          <a href="tel:+1-800-482-2712">1-800-482-2712</a><br>
          <a
            class="email-link"
            href="mailto:support@homereserve.com"
          >support@homereserve.com</a><br>
          <n-link to="/policy">
            Privacy & Policies
          </n-link>
          <a
            v-show="mobile()"
            class="mt-4"
            href="https://www.homereservepartners.com/"
            target="_blank"
          >
            Partnership Application
          </a><br>
          <button
            aria-label="Accessibility options"
            @click="showAccessibility()"
          >
            <v-img
              :contain="true"
              class="mt-5 pointer"
              :width="50"
              :height="50"
              :src="require('@/assets/images/global/accessibility.png')"
            />
          </button>
          <h4 class="font-weight-bold qwincey mt-10">
            {{ mobile() ? '' : 'Social' }}
          </h4>
          <div class="footer__social-media">
            <a
              aria-label="HomeReserve Facebook"
              href="https://www.facebook.com/HomeReserve"
              target="_blank"
            >
              <v-img
                :contain="true"
                class="ma-2"
                :width="50"
                :height="50"
                :src="require('@/assets/images/global/fb.svg')"
              />
            </a>
            <a
              aria-label="HomeReserve Instagram"
              href="https://www.instagram.com/homereserve/"
              target="_blank"
            >
              <v-img
                :contain="true"
                class="ma-2"
                :width="50"
                :height="50"
                :src="require('@/assets/images/global/instagram.svg')"
              />
            </a>
            <a
              aria-label="HomeReserve Youtube"
              href="https://www.youtube.com/user/HomeReserve"
              target="_blank"
            >
              <v-img
                :contain="true"
                class="ma-2"
                :width="50"
                :height="50"
                :src="require('@/assets/images/global/youtube.svg')"
              />
            </a>
            <a
              aria-label="HomeReserve Pinterest"
              href="https://www.pinterest.com/homereserve/_created/"
              target="_blank"
            >
              <v-img
                :contain="true"
                class="ma-2"
                :width="50"
                :height="50"
                :src="require('@/assets/images/social/pinterest.png')"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      links: [
        { name: 'Built-In Storage', route: '/features#storage' },
        { name: 'Changeable Fabrics', route: '/features#fabric-covers' },
        { name: 'Changeable Styles', route: '/features#styles' },
        { name: 'Changeable Layouts', route: '/features#layouts' },
        { name: 'Family Care Fabrics', route: '/features#pet-family' },
        { name: 'Comfort Deck', route: '/features#comfort-deck' },
        { name: '10-Year Warranty', route: '/features#warranty' },
        { name: 'Easy-Carry Boxes', route: '/features#ups' },
        { name: 'Renewability', route: '/features#features-renewability-info' },
      ],
      menuItems: [
        { name: 'Deep Seat Sectional', slug: 'deep-seat-sectionals' },
        { name: 'Standard Seat Sectional', slug: 'standard-seat-sectionals' },
        { name: 'Sofa', slug: 'sofas' },
        { name: 'Couch', slug: 'couches' },
        { name: 'Loveseat', slug: 'loveseats' },
        { name: 'Chair', slug: 'chairs' },
        { name: 'Chair & Half', slug: 'chair-half' },
        { name: 'Ottoman', slug: 'ottomans' },
        { name: 'New Fabric Cover', slug: 'covers' },
        { name: '$1 Fabric Sample', slug: 'swatches' },
      ],
    }),
    computed: {
      ...mapGetters({
        // menuItems: 'initData/menuItems',
        latestCommit: 'initData/latestCommit',
      }),
    },
    methods: {
      menuLink (slug) {
        if (slug === 'new-covers') { return '/shop/covers/' }
        return `/shop/${slug}`
      },
      showAccessibility () {
        window.interdeal.a11y.openMenu()
      },
    },
  }
</script>
<style lang=scss scoped>
  .footer {
    overflow: hidden;
    background-color: #8C9EA9 !important;
    color: $white !important;
    h6, .v-list-item__title {
      color: $white !important;
    }
    .v-list a.v-list-item, a {
      color: $white !important;
      min-height: 25px;
      padding: 0;
      text-decoration: none;
      transition: $global-transition;
      &:hover {
        opacity: .7;
        transition: $global-transition;
      }
      &:before {
        display: none;
      }
    }
    &__social-media .v-image {
      display: inline-block;
      margin: $global-margin $global-margin 0 0 !important;
    }
    .v-list {
      background-color: transparent !important;
    }
  }
  #INDmenu-btn {
    display: none !important;
  }
</style>
