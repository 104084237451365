<template>
  <v-row
    :class="greyOrWhite()"
    class="ma-0"
  >
    <v-col
      class="d-flex align-center flex-grow-1 flex-shrink-0"
    >
      <h6
        v-if="item.sectionalName"
        class="font-weight-regular mb-0"
      >
        {{ `${item.sectionalName} ${item.classificationName}` }}
        <br>
        <b>
          {{`${item.productName}`}}
        </b>
      </h6>
      <h6
        v-if="!item.sectionalName"
        class="mb-0"
      >
        {{item.name}}
      </h6>
    </v-col>
    <v-col
      :class="mobile() ? 'd-flex align-center' : 'd-flex align-center flex-grow-1 flex-shrink-0'"
    >
      <h6 class="font-weight-regular mb-0">
        {{ item.fabric }}
      </h6>
    </v-col>
    <v-col
    :class="mobile() ? 'd-flex align-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto' : 'd-flex align-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto line-items__price'"
    >
      <h6 class="font-weight-regular mb-0">
        {{ priceOfItem(item) | currency({fractionCount: ''}) }}
      </h6>
    </v-col>
    <v-col :class="mobile() ? 'd-flex align-center' : 'd-flex align-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto line-items__qty'">
      <v-text-field
        v-model="value"
        outlined
        dense
        hide-details="true"
        :min="0"
        type="Number"
        :disabled="enrolledInRiskFree && !item.name.includes('Fabric Swatch')"
      />
    </v-col>
    <v-col
      :class="mobile() ? 'd-none' : 'd-flex align-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto line-items__line-total'"
    >
      <h6
        class="font-weight-regular mb-0"
      >
        {{ priceOfItemWithCount(item) | currency({fractionCount: ''}) }}
      </h6>
    </v-col>
    <v-col
      v-if="!enrolledInRiskFree || item.name.includes('Fabric Swatch')"
      :class="mobile() ? 'd-flex justify-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto' : 'd-flex justify-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto'"
      @click="destroy ()"
    >
      <v-icon class="grey--text pointer">
        mdi-close
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import helpers from '../helpers/duplicates.vue'

  export default {
    props: {
      index: {
        type: Number,
        default: 0,
        required: true,
      },
      item: {
        type: Object,
        default () { return { styleProduct: { displayName: 'No Items Selected', price: 999999 } } },
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        essentials: 'initData/essentialFabrics',
        easyliving: 'initData/easylivingFabrics',
        exclusive: 'initData/exclusiveFabrics',
        enrolledInRiskFree: 'shopping/enrolledInRiskFree',
      }),
      value: {
        get () { return this.item.quantity },
        set (value) {
          const self = this
          if (Number.isNaN(parseInt(value, 10))) { return }
          if (value === '0') {
            // eslint-disable-next-line consistent-return
            return self.deleteLineItem(self.item)
          }
          // eslint-disable-next-line consistent-return
          return self.modifyTotalLineItem({ quantity: value, item: self.item })
        },
      },
    },
    methods: {
      ...mapActions({
        deleteLineItem: 'cart/deleteLineItem',
        modifyTotalLineItem: 'cart/modifyTotalLineItem',
      }),
      destroy () {
        return this.deleteLineItem(this.item)
      },
      greyOrWhite () {
        return this.index % 2 === 0 ? 'grey lighten-3' : 'white'
      },
      priceOfItem (item) {
        return helpers.priceOfItem(item, this)
      },
      priceOfItemWithCount (item) {
        return helpers.priceOfItemWithCount(item, this)
      },
    },
  }
</script>

<style lang="scss" scoped>
.medium-font-text {
  font-size: medium;
}

.line-items {
  &__price {
    min-width: 100px !important;
  }
  &__qty {
    max-width: 130px;
    padding-right: 30px;
  }
  &__line-total {
    min-width: 100px !important;
  }
}
</style>
