<template>
  <div id="error-page">
    <v-container
      fluid
      class="pa-0"
    >
      <v-container
        class="pa-0"
      >
        <v-row
          class="ma-0 justify-center"
        >
          <v-col
            class="order-last order-md-first"
            cols="12"
            md="6"
          >
            <the-six-pictures class="pb-6" />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <h2
              :class="mobile() ? 'h2-text-mobile' : 'h2-text'"
            >
              Page Moved
            </h2>
            <h4>
              This page must have been moved when we updated our site.
            </h4>
            <n-link
              to="/"
            >
              <v-btn
                color="green-color"
                class="mt-14 mb-4 pa-6 text-capitalize white--text"
                depressed
                large
              >
                Go To Our New Homepage
                <client-only>
                  <cld-image
                    public-id="extras/Arrow_White_Color.png"
                    class="pl-4"
                    height="14"
                  />
                </client-only>
              </v-btn>
            </n-link>
            <h6 class="mt-2">
              <strong>Shop:</strong>
              <n-link to="/shop/deep-seat-sectionals" class="underline">
                Sectionals,
              </n-link>
              <n-link to="/shop/sofas" class="underline">
                Sofas,
              </n-link>
              <n-link to="/shop/couches" class="underline">
                Couches,
              </n-link>
              <n-link to="/shop/loveseats" class="underline">
                Loveseats,
              </n-link>
              <n-link to="/shop/chairs" class="underline">
                Chairs,
              </n-link>
              <n-link to="/shop/swatches" class="underline">
                Fabric Swatches
              </n-link>
            </h6>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script scoped>
  import TheSixPictures from '~/components/classifications/TheSixPictures.vue'

  export default {
    name: 'NotFoundPage',
    components: {
      TheSixPictures,
    },
    layout: 'empty',
    props: {
      error: {
        type: Object,
        default: null,
      },
    },
    /* eslint-disable */
    head () {
        console.log(this.error)
      const title =
        this.error.statusCode === 404 ? this.pageNotFound : this.otherError
        return {
          title,
      }
    },
    data () {
      return {
        apologies: "An error occurred, we're sorry!",
        otherError: 'An error occurred',
      }
    }
  }
</script>

<style scoped>
.h2-text{
  margin-top: 140px;
}
.h2-text-mobile{
  margin-top: 50px;
}
</style>
