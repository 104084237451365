export const state = () => ({
  shippingFirstname: '',
  shippingLastname: '',
  shippingEmail: '',
  shippingZip: '',
  shippingState: '',
  shippingStateAbbreviation: '',
  shippingCity: '',
  shippingAddressLine1: '',
  shippingAddressLine2: '',
  shippingPhone: '',
  shippingNoEmail: false,
  billingFirstname: '',
  billingLastname: '',
  billingZip: '',
  billingState: '',
  billingStateAbbreviation: '',
  billingCity: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingBillLater: false,
  paymentCvv: '',
  paymentFirstname: '',
  paymentLastname: '',
  paymentCardType: '',
  paymentNumbers: '',
  paymentExpirationMonth: '',
  paymentExpirationYear: '',
  railsApiOrderId: '',
  transactionId: '',
})

export const getters = {
  apiAuthorization: (state) => {
    return `${state.transactionId}#${state.paymentNumbers}#authorize`
  },
  allShippingInformation: (state) => {
    return {
      first_name: state.shippingFirstname,
      last_name: state.shippingLastname,
      zip: state.shippingZip,
      state: state.shippingState,
      city: state.shippingCity,
      street1: state.shippingAddressLine1,
      street2: state.shippingAddressLine2,
    }
  },
  allBillingInformation: (state) => {
    return {
      first_name: state.billingFirstname,
      last_name: state.billingLastname,
      zip: state.billingZip,
      state: state.billingState,
      city: state.billingCity,
      street1: state.billingAddressLine1,
      street2: state.billingAddressLine2,
    }
  },
  allCustomerInformation: (state) => {
    return {
      email: state.shippingEmail,
      phone: state.shippingPhone,
      disable_email: state.shippingNoEmail,
      bill_later: state.billingBillLater,
    }
  },
  allPaymentInformation: (state) => {
    return {
      first_name: state.paymentFirstname,
      last_name: state.paymentLastname,
      card_type: state.paymentCardType,
      payment_numbers_encrypted: state.paymentNumbers,
      expiration_month: state.paymentExpirationMonth,
      expiration_year: state.paymentExpirationYear,
      cvv: state.paymentCvv,
    }
  },
  shippingFirstname: (state) => {
    return state.shippingFirstname
  },
  shippingLastname: (state) => {
    return state.shippingLastname
  },
  shippingEmail: (state) => {
    return state.shippingEmail
  },
  shippingZip: (state) => {
    return state.shippingZip
  },
  shippingState: (state) => {
    return state.shippingState
  },
  shippingStateAbbreviation: (state) => {
    return state.shippingStateAbbreviation
  },
  shippingCity: (state) => {
    return state.shippingCity
  },
  shippingAddressLine1: (state) => {
    return state.shippingAddressLine1
  },
  shippingAddressLine2: (state) => {
    return state.shippingAddressLine2
  },
  shippingPhone: (state) => {
    return state.shippingPhone
  },
  shippingNoEmail: (state) => {
    return state.shippingNoEmail
  },
  billingFirstname: (state) => {
    return state.billingFirstname
  },
  billingLastname: (state) => {
    return state.billingLastname
  },
  billingZip: (state) => {
    return state.billingZip
  },
  billingState: (state) => {
    return state.billingState
  },
  billingStateAbbreviation: (state) => {
    return state.billingStateAbbreviation
  },
  billingCity: (state) => {
    return state.billingCity
  },
  billingAddressLine1: (state) => {
    return state.billingAddressLine1
  },
  billingAddressLine2: (state) => {
    return state.billingAddressLine2
  },
  billingBillLater: (state) => {
    return state.billingBillLater
  },
  paymentFirstname: (state) => {
    return state.paymentFirstname
  },
  paymentLastname: (state) => {
    return state.paymentLastname
  },
  paymentCardType: (state) => {
    return state.paymentCardType
  },
  paymentNumbers: (state) => {
    return state.paymentNumbers
  },
  paymentExpirationMonth: (state) => {
    return state.paymentExpirationMonth
  },
  paymentExpirationYear: (state) => {
    return state.paymentExpirationYear
  },
  transactionId: (state) => {
    return state.transactionId
  },
  railsApiOrderId: (state) => {
    return state.railsApiOrderId
  },
}

export const mutations = {
  setShippingFirstname (state, payload) {
    state.shippingFirstname = payload
  },
  setShippingLastname (state, payload) {
    state.shippingLastname = payload
  },
  setShippingEmail (state, payload) {
    state.shippingEmail = payload
  },
  setShippingZip (state, payload) {
    state.shippingZip = payload
  },
  setShippingState (state, payload) {
    state.shippingState = payload
  },
  setShippingStateAbbreviation (state, payload) {
    state.shippingStateAbbreviation = payload
  },
  setShippingCity (state, payload) {
    state.shippingCity = payload
  },
  setShippingAddressLine1 (state, payload) {
    state.shippingAddressLine1 = payload
  },
  setShippingAddressLine2 (state, payload) {
    state.shippingAddressLine2 = payload
  },
  setShippingPhone (state, payload) {
    state.shippingPhone = payload
  },
  setShippingNoEmail (state, payload) {
    state.shippingNoEmail = payload
  },
  setBillingFirstname (state, payload) {
    state.billingFirstname = payload
  },
  setBillingLastname (state, payload) {
    state.billingLastname = payload
  },
  setBillingZip (state, payload) {
    state.billingZip = payload
  },
  setBillingState (state, payload) {
    state.billingState = payload
  },
  setBillingStateAbbreviation (state, payload) {
    state.billingStateAbbreviation = payload
  },
  setBillingCity (state, payload) {
    state.billingCity = payload
  },
  setBillingAddressLine1 (state, payload) {
    state.billingAddressLine1 = payload
  },
  setBillingAddressLine2 (state, payload) {
    state.billingAddressLine2 = payload
  },
  setBillingBillLater (state, payload) {
    state.billingBillLater = payload
  },
  setPaymentFirstname (state, payload) {
    state.paymentFirstname = payload
  },
  setPaymentLastname (state, payload) {
    state.paymentLastname = payload
  },
  setPaymentCardType (state, payload) {
    state.paymentCardType = payload
  },
  // encrypted by JWT
  setPaymentNumbers (state, payload) {
    state.paymentNumbers = payload
  },
  setPaymentCvv (state, payload) {
    state.paymentCvv = payload
  },
  setPaymentExpirationMonth (state, payload) {
    state.paymentExpirationMonth = payload
  },
  setPaymentExpirationYear (state, payload) {
    state.paymentExpirationYear = payload
  },
  setTransactionId (state, transactionId) {
    state.transactionId = transactionId
  },
  setRailsApiOrderId (state, orderId) {
    state.railsApiOrderId = orderId
  },
}

export const actions = {
  addressValidation ({ commit }, payload) {
    commit('setShippingFirstname', payload.firstname)
    commit('setShippingLastname', payload.lastname)
    commit('setShippingZip', payload.zip)
    commit('setShippingState', payload.state)
    commit('setShippingStateAbbreviation', payload.stateAbbreviation)
    commit('setShippingCity', payload.city)
    commit('setShippingAddressLine1', payload.addressLine1)
    commit('setShippingAddressLine2', payload.addressLine2)
  },
  setBillingData ({ commit }, payload) {
    commit('setBillingFirstname', payload.firstname)
    commit('setBillingLastname', payload.lastname)
    commit('setBillingZip', payload.zip)
    commit('setBillingState', payload.state)
    commit('setBillingStateAbbreviation', payload.stateAbbreviation)
    commit('setBillingCity', payload.city)
    commit('setBillingAddressLine1', payload.addressLine1)
    commit('setBillingAddressLine2', payload.addressLine2)
    commit('setBillingBillLater', payload.billLater)
  },
  setShippingData ({ commit }, payload) {
    commit('setShippingFirstname', payload.firstname)
    commit('setShippingLastname', payload.lastname)
    commit('setShippingEmail', payload.email)
    commit('setShippingZip', payload.zip)
    commit('setShippingState', payload.state)
    commit('setShippingStateAbbreviation', payload.stateAbbreviation)
    commit('setShippingCity', payload.city)
    commit('setShippingAddressLine1', payload.addressLine1)
    commit('setShippingAddressLine2', payload.addressLine2)
    commit('setShippingPhone', payload.phone)
    commit('setShippingNoEmail', payload.noEmail)
  },
  setPaymentData ({ commit }, payload) {
    commit('setPaymentFirstname', payload.firstname)
    commit('setPaymentLastname', payload.lastname)
    commit('setPaymentCardType', payload.cardtype)
    commit('setPaymentNumbers', payload.numbers)
    commit('setPaymentExpirationMonth', payload.month)
    commit('setPaymentExpirationYear', payload.year)
    commit('setPaymentCvv', payload.cvv)
  },
}
