import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fb4ecdfa = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _a77ddd74 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _a898d15e = () => interopDefault(import('../pages/blog/topic/_id/index.vue' /* webpackChunkName: "pages/blog/topic/_id/index" */))
const _2a033827 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _84a01fb8 = () => interopDefault(import('../pages/customer_comments.vue' /* webpackChunkName: "pages/customer_comments" */))
const _1583577b = () => interopDefault(import('../pages/customer-layouts.vue' /* webpackChunkName: "pages/customer-layouts" */))
const _b3e24bba = () => interopDefault(import('../pages/family-friendly.vue' /* webpackChunkName: "pages/family-friendly" */))
const _5bc6178e = () => interopDefault(import('../pages/favorite-layouts.vue' /* webpackChunkName: "pages/favorite-layouts" */))
const _1bf27b9e = () => interopDefault(import('../pages/features.vue' /* webpackChunkName: "pages/features" */))
const _e23a4efe = () => interopDefault(import('../pages/gallery.vue' /* webpackChunkName: "pages/gallery" */))
const _bf6c5fee = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _7a6d2ee1 = () => interopDefault(import('../pages/instruction-booklets.vue' /* webpackChunkName: "pages/instruction-booklets" */))
const _3e70f9fc = () => interopDefault(import('../pages/made_in_usa.vue' /* webpackChunkName: "pages/made_in_usa" */))
const _0b5815d3 = () => interopDefault(import('../pages/no_risk_trial.vue' /* webpackChunkName: "pages/no_risk_trial" */))
const _44f325e8 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _17e80f5a = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _a14be644 = () => interopDefault(import('../pages/renewability.vue' /* webpackChunkName: "pages/renewability" */))
const _2dcc8ca2 = () => interopDefault(import('../pages/risk_free_trial.vue' /* webpackChunkName: "pages/risk_free_trial" */))
const _3ea0c283 = () => interopDefault(import('../pages/shipping-updates-status.vue' /* webpackChunkName: "pages/shipping-updates-status" */))
const _beb34b04 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _674aab56 = () => interopDefault(import('../pages/video_tips_for_fabrics.vue' /* webpackChunkName: "pages/video_tips_for_fabrics" */))
const _879a6d68 = () => interopDefault(import('../pages/employees/sign_in.vue' /* webpackChunkName: "pages/employees/sign_in" */))
const _99eb65f8 = () => interopDefault(import('../pages/shop/covers.vue' /* webpackChunkName: "pages/shop/covers" */))
const _393d3f5c = () => interopDefault(import('../pages/shop/swatches.vue' /* webpackChunkName: "pages/shop/swatches" */))
const _ea10f612 = () => interopDefault(import('../pages/shop/swatches-2.vue' /* webpackChunkName: "pages/shop/swatches-2" */))
const _d41445aa = () => interopDefault(import('../pages/orders/thank_you/_searchQueryParam/_orderId.vue' /* webpackChunkName: "pages/orders/thank_you/_searchQueryParam/_orderId" */))
const _533024a2 = () => interopDefault(import('../pages/post/_id/index.vue' /* webpackChunkName: "pages/post/_id/index" */))
const _12550a68 = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _2b230255 = () => interopDefault(import('../pages/shop/_category/chair_half_text.vue' /* webpackChunkName: "pages/shop/_category/chair_half_text" */))
const _31dbaf7e = () => interopDefault(import('../pages/shop/_category/chair_text.vue' /* webpackChunkName: "pages/shop/_category/chair_text" */))
const _0364b510 = () => interopDefault(import('../pages/shop/_category/couch_text.vue' /* webpackChunkName: "pages/shop/_category/couch_text" */))
const _48284dd0 = () => interopDefault(import('../pages/shop/_category/deep_seat_sectionals_text.vue' /* webpackChunkName: "pages/shop/_category/deep_seat_sectionals_text" */))
const _18865e0b = () => interopDefault(import('../pages/shop/_category/loveseat_text.vue' /* webpackChunkName: "pages/shop/_category/loveseat_text" */))
const _23d6cfec = () => interopDefault(import('../pages/shop/_category/ottoman_text.vue' /* webpackChunkName: "pages/shop/_category/ottoman_text" */))
const _8741f532 = () => interopDefault(import('../pages/shop/_category/standard_seat_sectionals_text.vue' /* webpackChunkName: "pages/shop/_category/standard_seat_sectionals_text" */))
const _252166ca = () => interopDefault(import('../pages/shop/_category/_classification.vue' /* webpackChunkName: "pages/shop/_category/_classification" */))
const _6c0b8f72 = () => interopDefault(import('../pages/orders/_email/_zipcode/order_lookup.vue' /* webpackChunkName: "pages/orders/_email/_zipcode/order_lookup" */))
const _60ffc3c1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _fb4ecdfa,
    children: [{
      path: "",
      component: _a77ddd74,
      name: "blog"
    }, {
      path: "topic/:id",
      component: _a898d15e,
      name: "blog-topic-id"
    }]
  }, {
    path: "/checkout",
    component: _2a033827,
    name: "checkout"
  }, {
    path: "/customer_comments",
    component: _84a01fb8,
    name: "customer_comments"
  }, {
    path: "/customer-layouts",
    component: _1583577b,
    name: "customer-layouts"
  }, {
    path: "/family-friendly",
    component: _b3e24bba,
    name: "family-friendly"
  }, {
    path: "/favorite-layouts",
    component: _5bc6178e,
    name: "favorite-layouts"
  }, {
    path: "/features",
    component: _1bf27b9e,
    name: "features"
  }, {
    path: "/gallery",
    component: _e23a4efe,
    name: "gallery"
  }, {
    path: "/inspire",
    component: _bf6c5fee,
    name: "inspire"
  }, {
    path: "/instruction-booklets",
    component: _7a6d2ee1,
    name: "instruction-booklets"
  }, {
    path: "/made_in_usa",
    component: _3e70f9fc,
    name: "made_in_usa"
  }, {
    path: "/no_risk_trial",
    component: _0b5815d3,
    name: "no_risk_trial"
  }, {
    path: "/partners",
    component: _44f325e8,
    name: "partners"
  }, {
    path: "/policy",
    component: _17e80f5a,
    name: "policy"
  }, {
    path: "/renewability",
    component: _a14be644,
    name: "renewability"
  }, {
    path: "/risk_free_trial",
    component: _2dcc8ca2,
    name: "risk_free_trial"
  }, {
    path: "/shipping-updates-status",
    component: _3ea0c283,
    name: "shipping-updates-status"
  }, {
    path: "/support",
    component: _beb34b04,
    name: "support"
  }, {
    path: "/video_tips_for_fabrics",
    component: _674aab56,
    name: "video_tips_for_fabrics"
  }, {
    path: "/employees/sign_in",
    component: _879a6d68,
    name: "employees-sign_in"
  }, {
    path: "/shop/covers",
    component: _99eb65f8,
    name: "shop-covers"
  }, {
    path: "/shop/swatches",
    component: _393d3f5c,
    name: "shop-swatches"
  }, {
    path: "/shop/swatches-2",
    component: _ea10f612,
    name: "shop-swatches-2"
  }, {
    path: "/orders/thank_you/:searchQueryParam?/:orderId?",
    component: _d41445aa,
    name: "orders-thank_you-searchQueryParam-orderId"
  }, {
    path: "/post/:id",
    component: _533024a2,
    name: "post-id"
  }, {
    path: "/shop/:category",
    component: _12550a68,
    name: "shop-category"
  }, {
    path: "/shop/:category?/chair_half_text",
    component: _2b230255,
    name: "shop-category-chair_half_text"
  }, {
    path: "/shop/:category?/chair_text",
    component: _31dbaf7e,
    name: "shop-category-chair_text"
  }, {
    path: "/shop/:category?/couch_text",
    component: _0364b510,
    name: "shop-category-couch_text"
  }, {
    path: "/shop/:category?/deep_seat_sectionals_text",
    component: _48284dd0,
    name: "shop-category-deep_seat_sectionals_text"
  }, {
    path: "/shop/:category?/loveseat_text",
    component: _18865e0b,
    name: "shop-category-loveseat_text"
  }, {
    path: "/shop/:category?/ottoman_text",
    component: _23d6cfec,
    name: "shop-category-ottoman_text"
  }, {
    path: "/shop/:category?/standard_seat_sectionals_text",
    component: _8741f532,
    name: "shop-category-standard_seat_sectionals_text"
  }, {
    path: "/shop/:category?/:classification",
    component: _252166ca,
    name: "shop-category-classification"
  }, {
    path: "/orders/:email?/:zipcode?/order_lookup",
    component: _6c0b8f72,
    name: "orders-email-zipcode-order_lookup"
  }, {
    path: "/",
    component: _60ffc3c1,
    name: "index"
  }, {
    path: "/shop/*",
    alias: "/shopping/*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
