<template>
  <v-app>
    <the-top-navbar :loaded="loaded" />
    <the-middle-navbar />
    <client-only>
      <the-bottom-navbar />
    </client-only>
    <v-main>
      <nuxt />
      <checkout-modal />
    </v-main>
    <the-footer />
  </v-app>
</template>
<script>
  import Vue from 'vue'
  import TheTopNavbar from '~/components/defaults/TheTopNavbar.vue'
  import TheMiddleNavbar from '~/components/defaults/TheMiddleNavbar.vue'
  import TheBottomNavbar from '~/components/defaults/TheBottomNavbar.vue'
  import TheFooter from '~/components/defaults/TheFooter.vue'
  import CheckoutModal from '~/components/CheckoutModal.vue'

  export default {
    components: {
      CheckoutModal,
      TheBottomNavbar,
      TheFooter,
      TheTopNavbar,
      TheMiddleNavbar,
    },
    data () {
      return {
        clipped: false,
        itemCount: 0,
        drawer: false,
        fixed: false,
        miniVariant: false,
        title: 'Vuetify.js',
      }
    },
    head () {
      return {
        titleTemplate: '%s | HomeReserve: Adaptable Furniture for Everyday Life',
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          // { hid: 'Adapatible Furniture', name: 'Adapatible Furnitur', content: 'Adapatible Furnitur' },
        ],
        link: [
          {
            rel: 'icon',
            type: 'image/png',
            href: this.changefavicon(),
          },
          {
            rel: 'canonical',
            href: window.location.origin + this.$route.path,
          },
        ],
      }
    },
    computed: {
      loaded () {
        // methods put here are available globally
        Vue.mixin({
          methods: {
            mobile () {
              return !this.$vuetify.breakpoint?.mdAndUp
            },
          },
        })
        return this.$store.state.cart.status
      },
    },
    mounted () {
      // Get the viewport height and multiply it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01
      // Set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      // Update the value on resize (when mobile browsers hide address bars and browser navigation, or mobile change from portrait/landscape)
      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    },
    methods: {
      mobile () {
        return !this.$vuetify.breakpoint?.mdAndUp
      },
      changefavicon () {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return '/hr-white-favicon.png'
        }
          return '/favicon.ico'
      },
    },
  }
</script>
<style lang="scss">
  // So that the mobile navigation drawer will show
  .v-navigation-drawer__content, .v-list, aside {
    overflow: scroll;
    background: inherit;
  }
  .logo {
    /*text-align: left;*/
    /*margin: 10px 0 10px 0;*/
    color: $green-color;
    max-height: 35px;
    line-height: 0;
    flex: 4;
  }
  .logo-mobile {
    /*text-align: left;*/
    /*margin: 10px 0 10px 0;*/
    height: 32px;
    line-height: 0;
    flex: 4;
  }
  a, i {
    text-decoration: none;
  }
  /*.text-right {
    a {
      display: inline-block;
      padding: .5rem .75rem;
      vertical-align: middle;
      text-decoration: none !important;
      color: $green-color !important;
      &:hover {
        color: #f4f4f4 !important;
      }
    }
  }*/
  .notera {
    font-size: 36px;
    line-height: 20px;
/*    padding: 0 $global-padding 0 $global-padding !important;*/
  }
  /*.support {
    text-transform: uppercase;
  }*/
  .lato-regular { font-family: 'lato'; }
  .hide-overflow-modal .v--modal-box {
    overflow: hidden !important;
  }
  .finger-cursor {
    cursor: pointer;
  }
  #INDmenu-btn {
    display: none !important;
  }
</style>
