import helpers from './helpers'

export const state = () => ({
  gallery1: [],
  gallery2: [],
  gallery3: [],
  gallery4: [],
  chairShopping: [],
  chairhalfShopping: [],
  sofaShopping: [],
  couchShopping: [],
  sectionalShopping: [],
})

export const getters = {
  gallery1: (state) => {
    return state.gallery1
  },
  gallery2: (state) => {
    return state.gallery2
  },
  gallery3: (state) => {
    return state.gallery3
  },
  gallery4: (state) => {
    return state.gallery4
  },
  chairShopping: (state) => {
    return state.chairShopping
  },
  chairhalfShopping: (state) => {
    return state.chairhalfShopping
  },
  sofaShopping: (state) => {
    return state.sofaShopping
  },
  couchShopping: (state) => {
    return state.couchShopping
  },
  sectionalShopping: (state) => {
    return state.sectionalShopping
  },
}

export const mutations = {
  setAllGalleries (state, payload) {
    if (payload === undefined) { return }
    try {
      state.gallery1 = helpers.modifyGalleriesForVueLightGallery(payload.sectionals.data)
      state.gallery2 = helpers.modifyGalleriesForVueLightGallery(payload['sofas-couches'].data)
      state.gallery3 = helpers.modifyGalleriesForVueLightGallery(payload['loveseats-chairs'].data)
      state.gallery4 = helpers.modifyGalleriesForVueLightGallery(payload['deep-sectional-shopping'].data)
      state.chairShopping = helpers.modifyGalleriesForVueLightGallery(payload['chairs-shopping'].data)
      state.chairhalfShopping = helpers.modifyGalleriesForVueLightGallery(payload['chair-half-shopping'].data)
      state.sofaShopping = helpers.modifyGalleriesForVueLightGallery(payload['sofa-shopping'].data)
      state.couchShopping = helpers.modifyGalleriesForVueLightGallery(payload['couch-shopping'].data)
      state.sectionalShopping = helpers.modifyGalleriesForVueLightGallery(payload['sectional-shopping'].data)
    } catch (e) {
      console.error(e)
    }
  },
}
