// cannot depend on lodash for initData
// import _ from 'lodash'

export const state = () => ({
  list: [],
  classificationList: [],
  categoryDetails: {},
  fabrics: [],
  fabricDetails: {},
  classificationDetails: [],
  latestCommit: '',
  token: {},
  staticData: { leadTime: '15 business days', bannerText: 'Now Shipping Custom Furniture in 1 Week!' },
})

export const getters = {
  menuItems: (state) => {
    return state.list.filter(category => category.attributes.menuItem === true)
                     .sort((a, b) => { return a.attributes.menuPriorityId - b.attributes.menuPriorityId })
  },
  bottomMenuItems: (state) => {
    return state.list.filter(category => category.attributes.menuItem === false)
                     .sort((a, b) => { return a.attributes.menuPriorityId - b.attributes.menuPriorityId })
  },
  list: (state) => {
    return state.list
  },
  categoryDetails: (state) => {
    return state.categoryDetails
  },
  classificationDetails: (state) => {
    return state.classificationDetails
  },
  cantinaBlackFabric: (state) => {
    try {
      return state.fabrics['Exclusive Collection'].data.find((fabric) => fabric.attributes.name === 'Cantina Black').attributes
    } catch {
      console.error('Could not find Cantina Black')
      return {}
    }
  },
  essentialFabrics: (state) => {
    return state.fabrics['Essential Collection'].data.flatMap(x => x.attributes.name)
  },
  easylivingFabrics: (state) => {
    return state.fabrics['Easy-Living Collection'].data.flatMap(x => x.attributes.name)
  },
  exclusiveFabrics: (state) => {
    return state.fabrics['Exclusive Collection'].data.flatMap(x => x.attributes.name)
  },
  fabricDetails: (state) => {
    return state.fabricDetails
  },
  fabrics: (state) => {
    return state.fabricDetails.data.flatMap(x => x.attributes.name)
  },
  latestCommit: (state) => {
    return state.latestCommit
  },
  staticData: (state) => {
    return state.staticData
  },
  token: (state) => {
    return state.token
  },
}

function setFabricDetails (fabrics) {
  const details = {}
  Object.values(fabrics).flatMap(x => x.data).forEach((fabric) => {
    details[fabric.attributes.name] = {
      data: {
        id: fabric.id,
        type: 'fabricDetails',
        attributes: fabric.attributes,
      },
    }
  })
  return details
}

export const mutations = {
  setInitData (state, payload) {
    try {
      state.list = payload.categories.data
      state.categoryDetails = payload.categoryDetails
      state.fabrics = payload.fabrics
      state.fabricDetails = setFabricDetails(payload.fabrics)
      state.classificationDetails = payload.classificationDetails
      if (payload.staticContent !== undefined) {
        state.staticData = payload.staticContent
      }
    } catch (e) {
      console.error(e)
      console.error(payload)
    }
  },
  setLatestCommit (state, payload) {
    state.latestCommit = payload
  },
  setToken (state, payload) {
    state.token = payload
  },
}
