export const state = () => ({
  allPosts: [],
  topicOne: [],
  topicTwo: [],
  topicThree: [],
  topicFour: [],
  topicFive: [],
  topicSix: [],
  topicSeven: [],
  topicEight: [],
})

export const getters = {
  allPosts: (state) => {
    return state.allPosts
  },
  topicOne: (state) => {
    return state.topicOne
  },
  topicTwo: (state) => {
    return state.topicTwo
  },
  topicThree: (state) => {
    return state.topicThree
  },
  topicFour: (state) => {
    return state.topicFour
  },
  topicFive: (state) => {
    return state.topicFive
  },
  topicSix: (state) => {
    return state.topicSix
  },
  topicSeven: (state) => {
    return state.topicSeven
  },
  topicEight: (state) => {
    return state.topicEight
  },
}

export const mutations = {
  setAllPosts (state, payload) {
    state.allPosts = payload
    state.topicOne = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 1) }
    state.topicTwo = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 2) }
    state.topicThree = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 3) }
    state.topicFour = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 4) }
    state.topicFive = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 5) }
    state.topicSix = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 6) }
    state.topicSeven = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 7) }
    state.topicEight = { data: payload.data.filter(post => parseInt(post.attributes.topicId, 10) === 8) }
  },
}
