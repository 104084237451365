// TODO: Rename this integration - it's a misnomer because it's not providing 'admin' functionality

export default function ({ $axios }, inject) {
  const adminUrl = process.env.ADMIN_URL || 'http://localhost:3001/'
  const admin = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/json, */*',
      },
    },
  })

  admin.setBaseURL(`${adminUrl}api/`)

  // Inject to context as $admin
  inject('admin', admin)
}
