<script>
  import _ from 'lodash'

  export default {
    clickSubtract (displayName, price, currentCount, self) {
      if (currentCount > 0) {
        self.setShoppingTotal(self.shoppingTotal - parseFloat(price))
        if (currentCount === 1) {
           self.destroyComponent(displayName)
        } else {
          self.modifyComponentNumber({ name: displayName, number: -1 })
        }
        // fractions of pennies should round to zero
        if (self.shoppingTotal < 1) { self.setShoppingTotal(0) }
        // updates the total on screen
        self.setTotalWithFabric()
      }
    },
    displayPrice (component, fabricLevel) {
      try {
        return component[`tier${fabricLevel}Price`] || component.price
      } catch (error) {
        console.error(error.message)
        return '999'
      }
    },
    findStateFullName (self) {
      try {
        // eslint-disable-next-line consistent-return
        const selectedState = _.find(self.states, (stateObj) => {
          if (stateObj.name === self.state) {
            return stateObj.fullStateName
          }
        })
        self.fullStateName = selectedState.fullStateName
      } catch (e) {
        console.error(e)
        self.fullStateName = 'Indiana'
      }
      return self.fullStateName
    },
    priceOfItem (item, self) {
      return this.priceOfProduct(item.product || item.styleProduct, item.fabric, self)
    },
    priceOfProduct (product, fabric, self) {
      if (product.displayName.includes('Swatch')) { return product.price || 1 }

      if (self.essentials.includes(fabric)) {
        return parseFloat(product.price)
      } if (self.easyliving.includes(fabric)) {
        return parseFloat(product.tier2Price)
      } if (self.exclusive.includes(fabric)) {
        return parseFloat(product.tier3Price)
      }
      return 999999999.00
    },
    priceOfItemWithCount (item, self) {
      const price = this.priceOfProduct(item.product || item.styleProduct, item.fabric, self)
      return parseFloat((item.quantity || 1) * price)
    },
  }
</script>
