import _ from 'lodash'

// We will create a cart on nuxt server init if there's hasn't been any created
// If there's an existing cart it, then this store module gets set up on nuxt server init.
// otherwise we just tell the backend what's in the cart when items are created, added, or reduced
// We never pull from the back-end though unless it's on nuxt server init.
export const state = () => ({
  lineItems: [],
  cityTax: 0,
  countyTax: 0,
  specialDistrictTax: 0,
  stateTax: 0,
  cityTaxPercentage: '',
  countyTaxPercentage: '',
  specialDistrictTaxPercentage: '',
  stateTaxPercentage: '',
  taxes: 0,
  total: 0,
  shippingTaxable: false,
  status: false,
  cartId: 0,

})

export const getters = {
  allCartInfo: (state, getters) => {
    return {
      shipping: parseFloat(getters.totalShipping),
      product_total: getters.total,
      total: getters.total + getters.totalShipping + (parseFloat(state.taxes) || 0),
      tax: parseFloat(state.taxes),
      line_items: state.lineItems,
      id: state.cartId,
    }
  },
  taxParams: (state, getters) => {
    const lineItemsForApi = []
    _.each(state.lineItems, (item) => {
      const o = {
        category: item.category,
        classification: item.classification,
        fabric: item.fabric,
        name: item.name,
        product: item.styleProduct,
        quantity: item.quantity,
        url: item.url,
      }
      lineItemsForApi.push(o)
    })
    return {
      shipping: parseFloat(getters.totalShipping),
      product_total: getters.total,
      cart: {
        line_items_attributes: lineItemsForApi,
      },
    }
  },
  total: (state, getters, rootState, rootGetters) => {
    let totalPrice = 0
    _.each(state.lineItems, (item) => {
      if (item.styleProduct.displayName.includes('Swatch')) {
        totalPrice += item.quantity * item.styleProduct.price
      } else if (rootGetters['initData/essentialFabrics'].includes(item.fabric)) {
        totalPrice += item.quantity * parseFloat(item.styleProduct.tier1Price)
      } else if (rootGetters['initData/easylivingFabrics'].includes(item.fabric)) {
        totalPrice += item.quantity * parseFloat(item.styleProduct.tier2Price)
      } else if (rootGetters['initData/exclusiveFabrics'].includes(item.fabric)) {
        totalPrice += item.quantity * parseFloat(item.styleProduct.tier3Price)
      }
    })
    return totalPrice
  },
  lineItems: (state) => {
    return state.lineItems
  },
  numberOfItems: (state) => {
    const arrayOfNumbers = state.lineItems.map(obj => obj.quantity)
    const total = arrayOfNumbers.reduce((arrayOfNumbers, b) => {
      return parseInt(arrayOfNumbers, 10) + parseInt(b, 10)
    }, 0)
    return total
  },
  taxes: (state) => {
    return state.taxes
  },
  cityTax: (state) => {
    return state.cityTax
  },
  countyTax: (state) => {
    return state.countyTax
  },
  specialDistrictTax: (state) => {
    return state.specialDistrictTax
  },
  stateTax: (state) => {
    return state.stateTax
  },
  cityTaxPercentage: (state) => {
    return state.cityTaxPercentage
  },
  countyTaxPercentage: (state) => {
    return state.countyTaxPercentage
  },
  specialDistrictTaxPercentage: (state) => {
    return state.specialDistrictTaxPercentage
  },
  stateTaxPercentage: (state) => {
    return state.stateTaxPercentage
  },
  shippingTaxable: (state) => {
    return state.shippingTaxable
  },
  totalShipping: (state) => {
    return _.sum(state.lineItems.map(item => item.styleProduct.shipping * item.quantity))
  },
}

export const mutations = {
  clearLineItems (state) {
    const nrtEnrolled = this.getters['shopping/enrolledInRiskFree']
    if (nrtEnrolled) {
      state.lineItems = _.reject(state.lineItems, (item) => {
        return !item.styleProduct.displayName.includes('Swatch')
      })
    } else {
    state.lineItems = []
    }
  },
  createLineItemVuex (state, payload) {
    state.lineItems.push(payload)
  },
  clearArmless (state) {
    state.lineItems = _.reject(state.lineItems, (item) => {
      return item.styleProduct.displayName.includes('Armless')
    })
  },
  deleteLineItemVuex (state, lineItem) {
    state.lineItems = _.reject(state.lineItems, (item) => {
      return item.fabric === lineItem.fabric && item.name === lineItem.name
    })
  },
  modifyTotalLineItemVuex (state, payload) {
    payload.lineItem.quantity = payload.newNumber
  },
  modifyIdLineItemVuex (state, payload) {
    payload.lineItem.id = payload.newId
  },
  modifyShippingLineItemVuex (state, payload) {
    payload.lineItem.shipping = payload.newShipping
  },
  setTaxes (state, payload) {
    // allow a dev to just send in a number to set the tax
    if (typeof (payload) === 'number') {
      state.taxes = payload
    } else {
      state.taxes = parseFloat(payload.cart.data.attributes.tax).toFixed(2)
      state.cityTax = parseFloat(payload.cart.data.attributes.cityTax).toFixed(2)
      state.countyTax = parseFloat(payload.cart.data.attributes.countyTax).toFixed(2)
      state.specialDistrictTax = parseFloat(payload.cart.data.attributes.specialDistrictTax).toFixed(2)
      state.stateTax = parseFloat(payload.cart.data.attributes.stateTax).toFixed(2)
      state.cityTaxPercentage = (parseFloat(payload.cart.data.attributes.cityTaxPercentage) * 100).toFixed(3)
      state.countyTaxPercentage = (parseFloat(payload.cart.data.attributes.countyTaxPercentage) * 100).toFixed(3)
      state.specialDistrictTaxPercentage = (parseFloat(payload.cart.data.attributes.specialDistrictTaxPercentage) * 100).toFixed(3)
      state.stateTaxPercentage = (parseFloat(payload.cart.data.attributes.stateTaxPercentage) * 100).toFixed(3)
      state.shippingTaxable = payload.cart.data.attributes.shippingTaxable
      state.cartId = payload.cart.data.id
    }
  },
  updateProduct (state, payload) {
    payload.item.styleProduct = payload.styleProduct
  },
}

export const actions = {
  saveLineItem ({ commit, state }, payload) {
    // if the furniture type and fabric(ex. Bulldozer Surf Chair) are in cart then don't create it
    const inCart = _.findIndex(state.lineItems, (item) => {
      return (item.fabric.includes(payload.fabric) && item.name.includes(payload.name))
    })
    if (inCart < 0) {
      commit('createLineItemVuex', payload)
    } else {
      const lineItem = _.find(state.lineItems, (item) => {
        return (item.fabric === payload.fabric && item.name === payload.name)
      })
      commit('modifyTotalLineItemVuex', { lineItem, newNumber: parseInt(lineItem.quantity, 10) + parseInt(payload.quantity, 10) })
    }
  },
  deleteLineItem ({ commit }, payload) {
    commit('deleteLineItemVuex', payload)
  },
  // for increasing or decreasing quantity while item is in cart
  modifyTotalLineItem ({ commit, state }, payload) {
    const lineItem = _.find(state.lineItems, (item) => {
      return (item.fabric === payload.item.fabric && item.name === payload.item.name)
    })
    commit('modifyTotalLineItemVuex', { lineItem, newNumber: parseInt(payload.quantity, 10) })
  },
  updateLineItemId ({ commit, state }, payload) {
    const lineItem = _.find(state.lineItems, (item) => {
      return (item.fabric === payload.item.fabric && item.name === payload.item.name)
    })
    commit('modifyIdLineItemVuex', { lineItem, newId: parseInt(payload.id, 10) })
  },
}
