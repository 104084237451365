<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- Only displaying on xtra-large because mobile design is a nightmare -->
  <v-row :class="mobile() ? 'mt-4 ml-0 mb-0 mr-0' : 'mt-12 ml-0 mb-0 mr-12'">
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-1 pb-2 pl-0' : 'pt-0 pr-1 pb-1 pl-1'"
    >
      <v-sheet
        color="quinary-color"
        :height="mobile() ? '150px' : '100%'"
        class="d-flex align-center justify-center text-center pa-0"
        style="overflow: hidden; color: #515256;"
      >
        <div>
          <h2 class="mb-2 qwincey">
            Get The Look
          </h2>
          <h5
            v-show="!mobile()"
            class="secondary--text"
          >
            Something For The Whole Family
          </h5>
        </div>
      </v-sheet>
    </v-col>
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-0 pb-2 pl-1' : 'pt-0 pr-1 pb-1 pl-0'"
    >
      <v-img
        :height="mobile() ? 150 : 200"
        :alt="imageToolTip(0)"
        :src="imageUrl(0)"
        @mouseover="picTwoDetails = true"
        @mouseleave="picTwoDetails = false"
      >
        <v-overlay
          :absolute="true"
          :opacity=".93"
          :value="picTwoDetails && !mobile()"
          color="quinary-color"
          class="ma-4 radius"
        >
          <div
            v-if="picTwoDetails && !mobile()"
            class="text-center pa-4"
          >
            <h5 class="mb-0 dark-gray--text uppercase mb-4">
              {{ imageToolTip(0) }}
            </h5>
          </div>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-1 pb-2 pl-0' : 'pt-0 pr-1 pb-1 pl-1'"
    >
      <v-img
        :height="mobile() ? 150 : 200"
        :alt="imageToolTip(1)"
        :src="imageUrl(1)"
        @mouseover="picThreeDetails = true"
        @mouseleave="picThreeDetails = false"
      >
        <v-overlay
          :absolute="true"
          :opacity=".93"
          :value="picThreeDetails && !mobile()"
          color="quinary-color"
          class="ma-4 radius"
          light
        >
          <div
            v-if="picThreeDetails && !mobile()"
            class="text-center pa-4"
          >
            <h5 class="mb-0 dark-gray--text uppercase mb-4">
              {{ imageToolTip(1) }}
            </h5>
          </div>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-0 pb-2 pl-1' : 'pt-0 pr-1 pb-1 pl-0'"
    >
      <v-img
        :width="576"
        :height="mobile() ? 150 : 200"
        :alt="imageToolTip(2)"
        :src="imageUrl(2)"
        @mouseover="picFourDetails = true"
        @mouseleave="picFourDetails = false"
      >
        <v-overlay
          :absolute="true"
          :opacity=".93"
          :value="picFourDetails && !mobile()"
          color="quinary-color"
          class="ma-4 radius"
          light
        >
          <div
            v-if="picFourDetails && !mobile()"
            class="text-center pa-4"
          >
            <h5 class="mb-0 dark-gray--text uppercase mb-4">
              {{ imageToolTip(2) }}
            </h5>
          </div>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-1 pb-0 pl-0' : 'pt-0 pr-1 pb-1 pl-1'"
    >
      <v-img
        :height="mobile() ? 150 : 200"
        :alt="imageToolTip(3)"
        :src="imageUrl(3)"
        @mouseover="picFiveDetails = true"
        @mouseleave="picFiveDetails = false"
      >
        <v-overlay
          :absolute="true"
          :opacity=".93"
          :value="picFiveDetails && !mobile()"
          color="quinary-color"
          class="ma-4 radius"
          light
        >
          <div
            v-if="picFiveDetails && !mobile()"
            class="text-center pa-4 overlay-text"
          >
            <h5 class="mb-0 dark-gray--text uppercase mb-4">
              {{ imageToolTip(3) }}
            </h5>
          </div>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      :cols="mobile() ? '6' : '6'"
      :class="mobile() ? 'pt-0 pr-0 pb-0 pl-1' : 'pt-0 pr-1 pb-1 pl-0'"
    >
      <v-img
        :height="mobile() ? 150 : 200"
        :alt="imageToolTip(4)"
        :src="imageUrl(4)"
        @mouseover="picSixDetails = true"
        @mouseleave="picSixDetails = false"
      >
        <v-overlay
          :absolute="true"
          :opacity=".93"
          :value="picSixDetails && !mobile()"
          color="quinary-color"
          class="ma-4 radius"
          light
        >
          <div
            v-if="picSixDetails && !mobile()"
            class="text-center pa-4"
          >
            <h5 class="mb-0 dark-gray--text uppercase mb-4">
              {{ imageToolTip(4) }}
            </h5>
          </div>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col cols="12">
      <n-link
        class="d-flex justify-center"
        to="/gallery"
      >
        View the Full Gallery
      </n-link>
    </v-col>
  </v-row>
</template>

<script scoped>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'TheSixPictures',
    data () {
      return {
        gallery: [],
        picTwoDetails: false,
        picThreeDetails: false,
        picFourDetails: false,
        picFiveDetails: false,
        picSixDetails: false,
      }
    },
    computed: {
      ...mapGetters({
        gallery1: 'galleries/gallery1',
        gallery2: 'galleries/gallery2',
        gallery3: 'galleries/gallery3',
        gallery4: 'galleries/gallery4',
        chairShopping: 'galleries/chairShopping',
        chairhalfShopping: 'galleries/chairhalfShopping',
        sofaShopping: 'galleries/sofaShopping',
        couchShopping: 'galleries/couchShopping',
        sectionalShopping: 'galleries/sectionalShopping',
        shoppingSelection: 'shopping/shoppingSelection',
      }),
    },
    methods: {
      imageSelections () {
        this.setGallery()
        try {
          const imagesByCategory = _.filter(this.gallery, (imageInfo) => {
            return imageInfo.tooltip.includes(_.capitalize(this.shoppingSelection))
          })
          if (imagesByCategory.length > 4) {
            return imagesByCategory
          }
          return _.concat(imagesByCategory, _.take(this.gallery, 5))
        } catch (e) {
          console.error(e)
          return this.gallery1
        }
      },
      setGallery () {
        switch (this.$route.params.category) {
          case 'sofas':
            this.gallery = this.sofaShopping
            break
          case 'couches':
            this.gallery = this.couchShopping
            break
          case 'loveseats':
            this.gallery = this.gallery3
            break
          case 'chairs':
            this.gallery = this.chairShopping
            break
          case 'standard-seat-sectionals':
            this.gallery = this.sectionalShopping
            break
          case 'deep-seat-sectionals':
            this.gallery = this.gallery4
            break
          case 'chair-half':
            // Couldn't concat without colliding with an infinite update
            this.gallery = this.chairShopping // this.chairhalfShopping.concat(this.chairShopping)
            break
          default:
            this.gallery = this.gallery1
        }
        // there are not enough images by category this way, but I assume they will want more specific images in the future
        // Anyways, here's the code to find them by category
        // galleryCategoryFilter (category, gallerySelection) {
        //   try {
        //     this.gallery = _.filter(gallerySelection, (imageInfo) => {
        //       return imageInfo.tooltip.includes(category)
        //     })
        //   } catch (e) {
        //     console.error(e)
        //     this.gallery = this.gallery1
        //   }
        // },
      },
      imageDescription (index) {
        if (this.imageSelections()[index] !== undefined) {
          return this.imageSelections()[index].description
        }
        return 'missing tool tip'
      },
      imageToolTip (index) {
        if (this.imageSelections()[index] !== undefined) {
          return this.imageSelections()[index].tooltip
        }
        return 'missing tool tip'
      },
      imageUrl (index) {
        if (this.imageSelections()[index] !== undefined) {
          return this.imageSelections()[index].url
        }
        return 'missing image url'
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
