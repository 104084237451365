export const state = () => ({
  loggedIn: false,
  email: '',
  passwordEncrypted: '',
})

export const getters = {
  loggedIn: (state) => {
    return state.loggedIn
  },
  email: (state) => {
    return state.email
  },
  passwordEncrypted: (state) => {
    return state.passwordEncrypted
  },
}

export const mutations = {
  login (state, payload) {
    state.loggedIn = true
    state.email = payload.data.user.email
    state.passwordEncrypted = payload.data.user.password
  },
  logout (state) {
    state.loggedIn = false
    state.email = ''
    state.passwordEncrypted = ''
  },
}
